@charset "UTF-8";

$zaux-helper-name: "color";

@mixin zaux-helper-color($name, $color) {
  .zaux-#{$zaux-helper-name}-#{$name} {
    color: $color !important;
  }
}

@each $colorName,
$colorVal in $zaux-color-set-default {
  @include zaux-helper-color('default-#{$colorName}', $colorVal);
}