@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("section-showroom");

.showroom {

    .content-wrapper {

        .content {
            height: 100%;
            padding-top: 40px;
            padding-bottom: 40px;
            border-top: 1px solid $zaux-color-default-black;
            border-bottom: 1px solid $zaux-color-default-black;
            justify-content: space-between;

            .h2 {
                margin-bottom: 80px;
            }

            .bottom-content {

                p {
                    margin-bottom: 80px;
                }

            }

        }

    }

    .showroom-preview-wrapper {

        div {
            height: 100%;
            width: 100%;
            position: relative;
            overflow: hidden;

            .showroom-preview {
                position: absolute;
                height: 100%;
                width: 100%;
            }

        }

    }

}



@media (max-width: $breakpoint-medium) {
    .showroom {
        min-height: 300px;

        .content-wrapper {

            .h2 {
                margin-bottom: 40px;
            }

            .content {
                padding-top: 20px;
                padding-bottom: 20px;

                .bottom-content {

                    p {
                        margin-bottom: 40px;
                    }

                }

            }

        }

    }

}