@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("section-articles");

.section-articles {

    .h2 {
        margin-bottom: 40px;
    }

    .blog-description {
        margin-bottom: 80px;
        width: 50%;
    }

    .blog-tags {
        margin-bottom: 80px;


        p {
            margin-bottom: 20px;
        }

        ul {
            overflow-x: auto;
            width: calc(100% + 24px);
            margin-left: -12px;
            padding-left: 12px;

            li {
                height: 40px;
                padding: 20px;
                border: 1px solid $zaux-color-default-black;
                margin-right: 12px;
                display: flex;
                align-items: center;
                cursor: pointer;
                opacity: 0.25;

                &:hover {
                    opacity: 1;
                }

            }

            .active {
                opacity: 1;
                position: relative;

                &:after {
                    content: '';
                    width: 15px;
                    height: 1px;
                    background-color: $zaux-color-default-black;
                    margin-left: 10px;
                    transform: rotate(45deg);
                }

                &:before {
                    content: '';
                    width: 15px;
                    height: 1px;
                    background-color: $zaux-color-default-black;
                    margin-left: 10px;
                    transform: rotate(-45deg) translate(-50%, -700%);
                    position: absolute;
                    right: 10px;
                }

            }

        }

    }

    .section-cards {
        margin-bottom: -80px;
    }

}

@media (max-width: $breakpoint-medium) {

    .section-articles {

        .blog-description {
            margin-bottom: 40px;
        }

        .blog-tags {
            margin-bottom: 40px;
        }

    }

}