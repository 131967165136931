@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("section-catalogue");

.catalogue {
    position: relative;

    .breadcrumb-outer {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
    }

    .aside-title {
        position: sticky;
        top: $zaux-header-height;
        height: auto;
        max-height: calc(100vh - 94px);

        .aside-title-bottom {

            .h2 {
                margin-bottom: 40px;
            }

            p {
                width: 75%;
            }

        }

    }

    .section-cards {
        margin-bottom: -80px;
        flex-grow: 1;
    }

}



@media (max-width: $breakpoint-medium) {

    .catalogue {

        .breadcrumb-outer {
            position: static;
            margin-top: 12px;
            margin-bottom: 40px;
        }

        .aside-title {
            position: static;
            height: auto;
            max-height: none;
            display: flex !important;
            justify-content: flex-end !important;

            .aside-title-bottom {
                position: sticky;
                bottom: 0;
                height: 0;
                width: auto;
                margin-bottom: -50px;

                .h2 {
                    width: 75vh;
                    transform-origin: top left;
                    transform: rotate(-90deg) translateX(12px);
                }

            }

        }

    }

}