@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("thumb-case");

.case-thumb-wrapper {
    padding: 40px 0;
    border-bottom: 1px solid $zaux-color-default-black;
    margin: 0;

    .thumb-content {
        padding: 0;

        .h4 {
            margin-bottom: 20px;
        }

        .right {

            p {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            .button {
                height: 0;
                margin: 0;
                opacity: 0;
            }

        }

    }

    &:hover {

        .h4 {
            margin-bottom: 40px;
        }

        .right {

            .button {
                height: auto;
                opacity: 1;
                margin-top: 40px;
            }

        }

    }

}

.case-thumb-wrapper,
.case-thumb-wrapper:hover,
.right,
.button,
.case-thumb-wrapper:hover .case-thumb-wrapper,
.case-thumb-wrapper:hover .right,
.case-thumb-wrapper:hover .button {
    transition: $zaux-transition-1;
}



@media (max-width: $breakpoint-medium) {

    .case-thumb-wrapper {
        padding: 12px 0 !important;
        margin: 0;

        .thumb-img-wrapper {
            position: relative;
            height: auto;
            overflow: hidden;
            max-height: calc(66vw - 24px);

            .case-thumb-img {
                width: 100%;
                height: 100%;
                position: absolute;
                transform: translateX(-12px);
            }

        }

        .thumb-content {
            padding: 0;
            padding-left: 6px !important;

            .h4 {
                margin-bottom: 20px;
                font-size: $zaux-text-big-size;
                line-height: $zaux-text-big-size;
            }

            .date {
                margin-bottom: 12px;
            }

            .right {

                p {
                    -webkit-line-clamp: 4 !important;
                }

                .button {
                    height: auto !important;
                    margin-top: 20px !important;
                    opacity: 1 !important;
                }

            }

        }

    }

}