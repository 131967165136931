@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("form-general");

input,
textarea {
    padding: 20px 24px !important;
    margin-bottom: 24px;
    font-size: $zaux-text-big-size;
    line-height: 30px;
    font-weight: 400;
    font-family: $zaux-font2;
    border: 1px solid $zaux-color-default-black !important;
    border-radius: 0 !important;

    &:focus {
        box-shadow: none !important;
        border-color: $zaux-color-default-blue !important;
        ;
    }

}

label {
    font-size: $zaux-text-small-size;
    line-height: 13px;
    font-weight: 400;
    font-family: $zaux-font2;

    a {
        text-decoration: underline;
    }

}

.form-check {
    padding: 12px 0 0 0;
    display: flex;
    align-items: flex-start;
}

.checkbox {
    margin: 0 12px 0 0 !important;
    padding: 0 !important;
    height: 16px;
    width: 16px;
    background-color: $zaux-color-default-white;

    &:checked {
        background-color: $zaux-color-default-black !important;
    }

}

.form-check-label {
    padding-top: 3px;
}

button {
    margin-top: 80px !important;
    margin-left: 12px !important;
    text-align: left;
    background-color: transparent;
}



@media (max-width: $breakpoint-medium) {

    input,
    textarea {
        padding: 20px 24px !important;
        margin-bottom: 24px;
        font-size: $zaux-text-small-size !important;
        line-height: $zaux-text-big-size;
        margin-bottom: 12px;

    }


    button {
        margin-top: 40px !important;
        margin-left: 6px !important;
    }

}