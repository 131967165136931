@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("consulting-description");

.consulting {
    margin-top: -40px;

    .h3 {
        margin-bottom: 20px;
    }

}

@media (max-width: $breakpoint-medium) {

    .consulting {
        margin-top: 0;

        .consulting-content {
            margin-bottom: 40px;

        }

        div:nth-last-child(2) {
            margin-bottom: 0;
        }

    }

}