@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("single-top");


.breadcrumb-wrapper {

    nav {

        .breadcrumb {

            display: flex;
            flex-direction: column;

            .breadcrumb-item {
                padding: 0;

                &:before {
                    content: none;
                }

                &:after {
                    content: ' >';
                    margin-right: 5px;
                }

                &:hover,
                &:active,
                &:focus {
                    text-decoration: underline;
                }

            }

        }

    }

}