@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("person");

.person {
    height: calc(16.6vw - 24px);

    .person-img {
        height: 100%;
    }

    .person-info {

        p {
            margin-top: 20px;
        }

    }

}



@media (max-width: $breakpoint-medium) {

    .person {
        height: calc(50vw - 12px);
        margin-bottom: 12px;

        .person-info {

            p {
                margin-top: 12px;
            }

        }

    }

}