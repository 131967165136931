@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("newsletter");

.newsletter {
    margin-bottom: 160px;

    .h2 {
        margin-bottom: 20px;
    }

    .disclaimer {
        margin-bottom: 80px;
    }

}



@media (max-width: $breakpoint-medium) {

    .newsletter {
        margin-bottom: 80px;

        .h2 {
            margin-bottom: 12px;
        }

        .disclaimer {
            margin-bottom: 40px;
        }

    }

}