@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("card");

.card {
    align-content: space-between;
    overflow: hidden;
    height: calc(100vw / 4);
    max-height: 500px;
    margin-bottom: 80px;

    .card-img {
        flex-grow: 1;
        height: 100px;
        transform: translateZ(0);
    }

    .card-content {
        position: relative;

        .h4,
        p {
            margin-top: 20px;
        }

        .h4 {
            text-transform: uppercase;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            line-height: $zaux-text-big-size;
        }

        .card-description {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            line-height: $zaux-text-small-size;
        }

        .button {
            height: 0;
            margin: 0;
            opacity: 0;
        }

    }

    &:hover {

        .card-content {

            .h4 {
                -webkit-line-clamp: 4;
            }

            .h4,
            p {
                margin-top: 40px;
            }

            .button {
                height: auto;
                opacity: 1;
            }

        }

    }

}


.card,
.card:hover,
.card-img,
.card:hover .card-img,
.card-content,
.card:hover .card-content,
.h4,
.card:hover .h4,
p,
.card:hover p,
.button,
.card:hover .button {
    -webkit-transition: $zaux-transition-1;
    -moz-transition: $zaux-transition-1;
    -o-transition: $zaux-transition-1;
    transition: $zaux-transition-1;
}




@media (max-width: $breakpoint-medium) {

    .card {
        margin-bottom: 40px;
        height: auto !important;
        max-height: none;
        overflow: visible;

        .card-img {
            flex-grow: 1;
            height: calc(50vw - 18px);
        }

        .card-content {

            .h4 {
                display: -webkit-box !important;
                -webkit-line-clamp: 4;
                line-height: $zaux-text-small-size;
            }

            p {
                display: none;
            }

            .button {
                height: auto !important;
                margin-top: 12px !important;
                opacity: 1 !important;
                display: block;
            }

        }

    }

    .keep-description {

        .card-content {

            .card-description {
                display: -webkit-box !important;
                -webkit-line-clamp: 4;
                margin-top: 12px;
            }

        }

    }

}