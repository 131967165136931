@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("consulting-top");

.not-found {
    height: calc(100vh - 70px);
    max-height: 1080px;
    display: flex;
    flex-direction: column;

    .not-found-img {
        object-fit: bottom;
        height: 100px;
        flex-grow: 1;
    }

    .h1 {
        margin: 40px 0 20px 0;
    }

    .h3 {
        line-height: $zaux-main-title-medium-size;
        max-width: 1920px;
    }

    a {
        margin: 40px 0 40px 0;
    }

}



@media (max-width: $breakpoint-medium) {

    .not-found {
        height: calc(100vh - 54px);
        max-height: none;

        .h1 {
            margin: 20px 0 20px 0;
        }

        .h3 {
            line-height: $zaux-main-title-small-size;
        }

        a {
            margin-bottom: 20px;
        }

    }

}