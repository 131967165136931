@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("consulting-top");

.consulting-top {
    height: calc(100vh - 70px);
    max-height: 1080px;
    display: flex;
    flex-direction: column;

    .consulting-img {
        height: 100px;
        flex-grow: 1;
    }

    .h1 {
        margin: 40px 0 40px 0;
    }

}



@media (max-width: $breakpoint-medium) {

    .consulting-top {
        height: calc(100vh - 54px);
        max-height: none;

        .h1 {
            margin: 20px 0 20px 0;
        }

    }

}