@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("section-about");

.about {
    padding-top: 80px;
    padding-bottom: 80px;

    .h3 {
        margin-bottom: 20px;
    }

}



@media (max-width: $breakpoint-medium) {

    .about {
        padding-top: 0;
        padding-bottom: 0;

        .h3 {
            margin-bottom: 20px;
        }

        p {
            margin-bottom: 40px;
        }

    }

}