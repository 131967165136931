@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("pagination");


.pagination {
    margin-top: 160px;

    a {

        &:nth-last-child(2) {
            margin-right: 40px;
        }

    }

    .disabled {
        pointer-events: none;
        opacity: 0.25;
    }

    .current {
        pointer-events: none;
        text-decoration: none;
    }

    .previous {
        margin-right: 40px;
    }

    .page {
        margin-right: 20px;
    }

    .next {
        margin-right: 0;
    }

}



@media (max-width: $breakpoint-medium) {

    .pagination {
        margin-top: 80px;
        padding-top: 40px;
    }

}