@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("article-paragraph");

.article-paragraph {
    padding-top: 40px;

    .paragraph-title {

        .h3 {
            font-size: 30px;
            position: sticky;
            top: $zaux-header-height;
            position: sticky;
        }

    }

    .line-wrapper {

        .line {
            border-top: 1px solid $zaux-color-default-black;
            margin-top: 40px;
        }

    }

}



@media (max-width: $breakpoint-medium) {

    .article-paragraph {
        padding-top: 20px;

        .paragraph-title {

            .h3 {
                font-size: 30px;
                line-height: 30px;
                position: static;
                margin-bottom: 40px;
            }

        }

        p {
            font-size: $zaux-text-big-size;
            line-height: 30px;
        }

    }

}