@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("section-case");

.section-case {

    .cases {

        .thumbs {

            a {
                display: block;

                &:first-of-type {
                    border-top: 1px solid $zaux-color-default-black;
                }

            }

            .case-button-wrapper {
                margin-top: 80px;

                a {
                    border-top: none;
                }

            }

        }

        .case-preview {
            position: sticky;
            top: $zaux-header-height;
        }

    }

}

@media (max-width: $breakpoint-medium) {

    .section-case {

        .cases {
            margin-top: 1px;

            .case-button-wrapper {
                margin-top: 40px !important;
            }

        }

    }

}