@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

p,
a {
  margin: 0;
  padding: 0; }

a,
a:hover,
a:visited {
  text-decoration: none; }

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
input,
textarea {
  color: #15191D !important; }

h1::selection,
h2::selection,
h3::selection,
h4::selection,
h5::selection,
h6::selection,
p::selection,
li::selection,
a::selection,
br::selection,
span::selection,
input::selection,
textarea::selection,
label::selection,
button::selection,
img::selection {
  background: #8CB5E2;
  color: #15191D; }

.breadcrumb {
  margin-bottom: 0; }

#zaux-content-outer,
#zaux-content-inner {
  display: unset; }

/* Fonts inclusions */
@font-face {
  font-family: "Albula Pro";
  src: url("../fonts/albulapro/albulapro-light.woff2") format("woff2"), url("../fonts/albulapro/albulapro-light.woff") format("woff");
  font-weight: 300; }

@font-face {
  font-family: "Albula Pro";
  src: url("../fonts/albulapro/albulapro-light-italic.woff2") format("woff2"), url("../fonts/albulapro/albulapro-light-italic.woff") format("woff");
  font-style: italic;
  font-weight: 300; }

/*
@font-face {
    font-family: $zaux-font1;
    src: url('../fonts/albulapro/albulapro-medium.woff2') format('woff2'),
    url('../fonts/albulapro/albulapro-medium.woff') format('woff');
    font-weight: $zaux-typo-fw-medium;
}
*/
/*
@font-face {
    font-family: $zaux-font1;
    src: url('../fonts/albulapro/albulapro-medium-italic.woff2') format('woff2'),
    url('../fonts/albulapro/albulapro-medium-italic.woff') format('woff');
    font-style: italic;
    font-weight: $zaux-typo-fw-medium;
}


@font-face {
    font-family: $zaux-font1;
    src: url('../fonts/albulapro/albulapro-italic.woff2') format('woff2'),
    url('../fonts/albulapro/albulapro-italic.woff') format('woff');
    font-style: italic;
}
*/
@font-face {
  font-family: "Albula Pro";
  src: url("../fonts/albulapro/albulapro-bold.woff2") format("woff2"), url("../fonts/albulapro/albulapro-bold.woff") format("woff");
  font-weight: 700; }

@font-face {
  font-family: "Albula Pro";
  src: url("../fonts/albulapro/albulapro-bold-italic.woff2") format("woff2"), url("../fonts/albulapro/albulapro-bold-italic.woff") format("woff");
  font-style: italic;
  font-weight: 700; }

/*
@font-face {
    font-family: $zaux-font1;
    src: url('../fonts/albulapro/albulapro-regular.woff2') format('woff2'),
    url('../fonts/albulapro/albulapro-regular.woff') format('woff');
    font-weight: $zaux-typo-fw-regular;
}


@font-face {
    font-family: $zaux-font1;
    src: url('../fonts/albulapro/albulapro-italic.woff2') format('woff2'),
    url('../fonts/albulapro/albulapro-italic.woff') format('woff');
    font-style: italic;
    font-weight: $zaux-typo-fw-regular;
}
*/
/*-- Font 2 --*/
/*
@font-face {
    font-family: $zaux-font2;
    src: url('../fonts/maax/maax-light.woff2') format('woff2'),
    url('../fonts/maax/maax-light.woff') format('woff');
    font-weight: $zaux-typo-fw-light;
}

@font-face {
    font-family: $zaux-font2;
    src: url('../fonts/maax/maax-light-italic.woff2') format('woff2'),
    url('../fonts/maax/maax-light-italic.woff') format('woff');
    font-style: italic;
    font-weight: $zaux-typo-fw-light;
}


@font-face {
    font-family: $zaux-font2;
    src: url('../fonts/maax/maax-medium.woff2') format('woff2'),
    url('../fonts/maax/maax-medium.woff') format('woff');
    font-weight: $zaux-typo-fw-medium;
}
*/
/*
@font-face {
    font-family: $zaux-font2;
    src: url('../fonts/maax/maax-medium-italic.woff2') format('woff2'),
    url('../fonts/maax/maax-medium-italic.woff') format('woff');
    font-style: italic;
    font-weight: $zaux-typo-fw-medium;
}
*/
@font-face {
  font-family: "Maax";
  src: url("../fonts/maax/maax-italic.woff2") format("woff2"), url("../fonts/maax/maax-italic.woff") format("woff");
  font-style: italic; }

@font-face {
  font-family: "Maax";
  src: url("../fonts/maax/maax-bold.woff2") format("woff2"), url("../fonts/maax/maax-bold.woff") format("woff");
  font-weight: 700; }

@font-face {
  font-family: "Maax";
  src: url("../fonts/maax/maax-bold-italic.woff2") format("woff2"), url("../fonts/maax/maax-bold-italic.woff") format("woff");
  font-style: italic;
  font-weight: 700; }

@font-face {
  font-family: "Maax";
  src: url("../fonts/maax/maax-regular.woff2") format("woff2"), url("../fonts/maax/maax-regular.woff") format("woff");
  font-weight: 400; }

.zaux-main-title {
  font-family: "Albula Pro"; }
  .zaux-main-title-big {
    font-size: 102px;
    line-height: 102px;
    letter-spacing: -0.024em;
    font-weight: 300;
    font-family: "Albula Pro"; }
  .zaux-main-title-big-bold {
    font-size: 102px;
    line-height: 102px;
    letter-spacing: -0.024em;
    font-weight: 700;
    font-family: "Albula Pro"; }
  .zaux-main-title-medium {
    font-size: 68px;
    line-height: 68px;
    letter-spacing: -0.024em;
    font-weight: 300;
    font-family: "Albula Pro"; }
  .zaux-main-title-medium-bold {
    font-size: 68px;
    line-height: 68px;
    letter-spacing: -0.024em;
    font-weight: 700;
    font-family: "Albula Pro"; }
  .zaux-main-title-small {
    font-size: 45px;
    line-height: 45px;
    letter-spacing: -0.024em;
    font-weight: 300;
    font-family: "Albula Pro"; }
  .zaux-main-title-small-bold {
    font-size: 45px;
    line-height: 45px;
    letter-spacing: -0.024em;
    font-weight: 700;
    font-family: "Albula Pro"; }

.zaux-text-big {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  font-family: "Maax"; }

.zaux-text-big-bold {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  font-family: "Maax"; }

.zaux-text-small {
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  font-family: "Maax"; }

.zaux-text-small-bold {
  font-size: 13px;
  line-height: 20px;
  font-weight: 700;
  font-family: "Maax"; }

@media (max-width: 992px) {
  .zaux-main-title {
    font-family: "Albula Pro"; }
    .zaux-main-title-big, .zaux-main-title-big-bold {
      font-size: 45px;
      line-height: 45px; }
    .zaux-main-title-medium, .zaux-main-title-medium-bold, .zaux-main-title-small, .zaux-main-title-small-bold {
      font-size: 30px;
      line-height: 30px; }
  .zaux-text-big, .zaux-text-big-bold {
    font-size: 13px;
    line-height: 20px; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src$=".svg"] {
    width: 100%; } }

#zaux-content-outer {
  overflow-x: hidden; }
  #zaux-content-outer #zaux-content-inner {
    overflow: hidden; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.zaux-fit-cover-center-center {
  object-fit: cover;
  object-position: center center;
  font-family: "object-fit: cover; object-position: center center;"; }

.zaux-color-default-white {
  color: #FFFFFF !important; }

.zaux-color-default-black {
  color: #15191D !important; }

.zaux-color-default-red {
  color: #d9534f !important; }

.zaux-color-default-green {
  color: #5cb85c !important; }

.zaux-color-default-blue {
  color: #8CB5E2 !important; }

.zaux-color-default-magenta {
  color: #FF00FF !important; }

.zaux-color-default-orange {
  color: #f0ad4e !important; }

.zaux-svg-fluid-wrapper {
  display: block;
  line-height: 0; }
  .zaux-svg-fluid-wrapper svg {
    max-width: 100%; }

:root:not(.ie-legacy) .zaux-svg-fluid-wrapper svg {
  height: auto; }

.container {
  padding: 0 24px;
  margin: 0 auto;
  max-width: none; }

.section {
  margin: 160px auto 320px auto; }

.section-first {
  margin-bottom: 320px; }

.top {
  padding-top: 70px; }

@media (max-width: 992px) {
  .container {
    padding: 0 12px;
    overflow: hidden; }
  .section {
    margin: 80px auto 160px auto; }
  .section-first {
    margin-bottom: 160px; }
  .top {
    padding-top: 54px; } }

.section-title {
  margin-bottom: 80px; }

.card-title {
  text-transform: uppercase; }

.cards-large-description a .card .card-content p {
  width: 75%; }

.vh-fit {
  height: calc(100vh - 94px);
  max-height: calc(100vh - 94px); }

.button {
  text-decoration: underline;
  padding: 0;
  border: none;
  color: #15191D; }
  .button:after {
    content: ' >'; }
  .button:hover, .button:active, .button:focus {
    text-decoration: none; }
    .button:hover:after, .button:active:after, .button:focus:after {
      content: '  >';
      white-space: pre; }

.link {
  text-decoration: underline;
  color: #15191D; }
  .link:hover, .link:active, .link:focus {
    text-decoration: none !important; }

.hide {
  display: none; }

.default-transition {
  transition: all 0.5s ease-out; }

@media (max-width: 992px) {
  .section-title {
    margin-bottom: 40px; }
  .big-button {
    font-size: 20px; } }

.home-top {
  height: calc(100vh - 289px) !important; }

@media (max-width: 992px) {
  .home-top {
    height: calc(100vh - 249px) !important; } }

@media (min-width: 993px) {
  .mobile-only {
    display: none !important; } }

@media (max-width: 992px) {
  .desktop-only {
    display: none !important; }
  .medium-full {
    width: 100% !important; }
  .medium-75 {
    width: 75% !important; }
  .medium-50 {
    width: 50% !important; }
  .medium-25 {
    width: 25% !important; }
  .medium-column {
    display: flex;
    flex-direction: column; }
  .medium-row {
    display: flex;
    flex-direction: row !important; }
  .medium-priority {
    display: flex;
    order: -1; }
  .responsive-width {
    max-width: 600px !important; }
  .responsive-overflow {
    overflow: visible; }
  .medium-truncate {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.header {
  height: 70px; }

header {
  position: fixed;
  top: 0;
  width: 100%;
  overflow: hidden;
  z-index: 10000000; }
  header .container {
    margin: 0 auto;
    width: 100%; }
    header .container .navbar-wrapper {
      height: 70px; }
      header .container .navbar-wrapper .navbar {
        height: 100%;
        display: flex;
        align-items: center;
        position: relative; }
        header .container .navbar-wrapper .navbar .c-header__logo img {
          height: 40px; }
        header .container .navbar-wrapper .navbar .hamburger,
        header .container .navbar-wrapper .navbar .close {
          position: absolute;
          right: 6px;
          display: block;
          width: 24px;
          height: 24px;
          padding: 0; }
        header .container .navbar-wrapper .navbar .close {
          transform: scale(0.8); }
        header .container .navbar-wrapper .navbar a:hover, header .container .navbar-wrapper .navbar a:active, header .container .navbar-wrapper .navbar a:focus {
          text-decoration: underline; }
  header:hover {
    overflow: visible; }
    header:hover .container .menu-wrapper {
      display: block !important; }

.sticky-header {
  -webkit-position: sticky !important;
  position: sticky !important;
  top: 0; }

@media (max-width: 992px) {
  .header {
    height: 54px; }
  .navbar-wrapper {
    height: 54px !important; }
    .navbar-wrapper .navbar .c-header__logo img {
      height: 30px !important; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.wrapper--w1 {
  max-width: 1920px; }

[class*="wrapper--"] {
  margin-right: auto;
  margin-left: auto; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slider__wrapper {
  overflow: hidden;
  position: relative; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

footer {
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column; }
  footer .footer-info {
    margin: 141px -12px 20px -12px; }
    footer .footer-info ul .zaux-text-big {
      padding-bottom: 20px; }
  footer .footer-img {
    flex-grow: 1;
    height: 50px; }
  footer .socket {
    height: 40px; }
    footer .socket a,
    footer .socket .zaki-sign {
      height: 40px; }

@media (min-width: 993px) and (max-height: 700px) {
  footer .footer-info {
    margin-top: 21px; } }

@media (max-width: 992px) {
  footer {
    height: calc(100vh - 54px);
    display: flex;
    flex-direction: column; }
    footer .footer-info {
      margin-top: 20px; }
      footer .footer-info ul {
        padding-bottom: 40px; }
        footer .footer-info ul .zaux-text-big {
          padding-bottom: 20px;
          font-size: 20px; }
        footer .footer-info ul li a {
          margin-top: 6px;
          display: block; }
        footer .footer-info ul:last-child {
          padding-bottom: 0; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.img {
  line-height: 0;
  display: block; }
  .img img {
    opacity: 1;
    transition: opacity 0.6s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .img:not(.img--lazy).img--fluid img {
    max-width: 100%;
    height: auto; }
  .img--lazy.img--fluid.-is-loaded img {
    max-width: 100%;
    height: auto; }
  .img--lazy:not([class*="-is-loaded"]) img {
    opacity: 0; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.menu {
  padding: 20px 0 19px 0;
  display: none;
  background-color: #FFFFFF; }
  .menu a:hover, .menu a:active, .menu a:focus {
    text-decoration: underline; }

@media (max-width: 992px) {
  .menu a {
    margin-bottom: 6px !important;
    display: block; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.highlights a {
  height: 100%; }
  .highlights a .single-highlight {
    align-content: space-between;
    overflow: hidden;
    max-height: 900px; }
    .highlights a .single-highlight .highlight-img {
      flex-grow: 1;
      height: 100px;
      transform: translateZ(0); }
    .highlights a .single-highlight .highlight-content {
      position: relative; }
      .highlights a .single-highlight .highlight-content .h3,
      .highlights a .single-highlight .highlight-content p {
        margin-top: 20px; }
      .highlights a .single-highlight .highlight-content .description {
        width: 50%; }
      .highlights a .single-highlight .highlight-content .button {
        height: 0;
        margin: 0;
        opacity: 0; }
  .highlights a:nth-child(2) .h3,
  .highlights a:nth-child(2) p {
    margin-top: 0 !important;
    margin-bottom: 20px; }
  .highlights a:nth-child(2) h3 {
    padding-top: 2px; }
  .highlights a:hover .single-highlight .highlight-content .h3,
  .highlights a:hover .single-highlight .highlight-content p {
    margin-top: 40px; }
  .highlights a:hover .single-highlight .highlight-content .button {
    height: auto;
    opacity: 1; }
  .highlights a:nth-child(2):hover .h3,
  .highlights a:nth-child(2):hover p,
  .highlights a:nth-child(2):hover .button {
    margin-bottom: 40px; }

a,
a:hover,
.highlights,
a:hover .highlights,
.single-highlight,
a:hover .single-highlight,
.highlight-img,
a:hover .highlight-img,
.highlight-content,
a:hover .highlight-content,
.h3,
a:hover .h3,
p,
a:hover p,
.button,
a:hover .button {
  -webkit-transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1); }

@media (max-width: 992px) {
  .highlights a {
    margin-bottom: 80px; }
    .highlights a .single-highlight {
      height: 450px; }
      .highlights a .single-highlight .highlight-content .h3,
      .highlights a .single-highlight .highlight-content p {
        margin-top: 20px !important;
        padding-top: 0%; }
      .highlights a .single-highlight .highlight-content .button {
        height: auto;
        opacity: 1;
        margin-top: 20px; }
    .highlights a:last-of-type {
      margin-bottom: 0; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.section-case .cases .thumbs a {
  display: block; }
  .section-case .cases .thumbs a:first-of-type {
    border-top: 1px solid #15191D; }

.section-case .cases .thumbs .case-button-wrapper {
  margin-top: 80px; }
  .section-case .cases .thumbs .case-button-wrapper a {
    border-top: none; }

.section-case .cases .case-preview {
  position: sticky;
  top: 70px; }

@media (max-width: 992px) {
  .section-case .cases {
    margin-top: 1px; }
    .section-case .cases .case-button-wrapper {
      margin-top: 40px !important; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.case-thumb-wrapper {
  padding: 40px 0;
  border-bottom: 1px solid #15191D;
  margin: 0; }
  .case-thumb-wrapper .thumb-content {
    padding: 0; }
    .case-thumb-wrapper .thumb-content .h4 {
      margin-bottom: 20px; }
    .case-thumb-wrapper .thumb-content .right p {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden; }
    .case-thumb-wrapper .thumb-content .right .button {
      height: 0;
      margin: 0;
      opacity: 0; }
  .case-thumb-wrapper:hover .h4 {
    margin-bottom: 40px; }
  .case-thumb-wrapper:hover .right .button {
    height: auto;
    opacity: 1;
    margin-top: 40px; }

.case-thumb-wrapper,
.case-thumb-wrapper:hover,
.right,
.button,
.case-thumb-wrapper:hover .case-thumb-wrapper,
.case-thumb-wrapper:hover .right,
.case-thumb-wrapper:hover .button {
  transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1); }

@media (max-width: 992px) {
  .case-thumb-wrapper {
    padding: 12px 0 !important;
    margin: 0; }
    .case-thumb-wrapper .thumb-img-wrapper {
      position: relative;
      height: auto;
      overflow: hidden;
      max-height: calc(66vw - 24px); }
      .case-thumb-wrapper .thumb-img-wrapper .case-thumb-img {
        width: 100%;
        height: 100%;
        position: absolute;
        transform: translateX(-12px); }
    .case-thumb-wrapper .thumb-content {
      padding: 0;
      padding-left: 6px !important; }
      .case-thumb-wrapper .thumb-content .h4 {
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 20px; }
      .case-thumb-wrapper .thumb-content .date {
        margin-bottom: 12px; }
      .case-thumb-wrapper .thumb-content .right p {
        -webkit-line-clamp: 4 !important; }
      .case-thumb-wrapper .thumb-content .right .button {
        height: auto !important;
        margin-top: 20px !important;
        opacity: 1 !important; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.about {
  padding-top: 80px;
  padding-bottom: 80px; }
  .about .h3 {
    margin-bottom: 20px; }

@media (max-width: 992px) {
  .about {
    padding-top: 0;
    padding-bottom: 0; }
    .about .h3 {
      margin-bottom: 20px; }
    .about p {
      margin-bottom: 40px; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.card {
  align-content: space-between;
  overflow: hidden;
  height: calc(100vw / 4);
  max-height: 500px;
  margin-bottom: 80px; }
  .card .card-img {
    flex-grow: 1;
    height: 100px;
    transform: translateZ(0); }
  .card .card-content {
    position: relative; }
    .card .card-content .h4,
    .card .card-content p {
      margin-top: 20px; }
    .card .card-content .h4 {
      text-transform: uppercase;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-height: 20px; }
    .card .card-content .card-description {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-height: 13px; }
    .card .card-content .button {
      height: 0;
      margin: 0;
      opacity: 0; }
  .card:hover .card-content .h4 {
    -webkit-line-clamp: 4; }
  .card:hover .card-content .h4,
  .card:hover .card-content p {
    margin-top: 40px; }
  .card:hover .card-content .button {
    height: auto;
    opacity: 1; }

.card,
.card:hover,
.card-img,
.card:hover .card-img,
.card-content,
.card:hover .card-content,
.h4,
.card:hover .h4,
p,
.card:hover p,
.button,
.card:hover .button {
  -webkit-transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1); }

@media (max-width: 992px) {
  .card {
    margin-bottom: 40px;
    height: auto !important;
    max-height: none;
    overflow: visible; }
    .card .card-img {
      flex-grow: 1;
      height: calc(50vw - 18px); }
    .card .card-content .h4 {
      display: -webkit-box !important;
      -webkit-line-clamp: 4;
      line-height: 13px; }
    .card .card-content p {
      display: none; }
    .card .card-content .button {
      height: auto !important;
      margin-top: 12px !important;
      opacity: 1 !important;
      display: block; }
  .keep-description .card-content .card-description {
    display: -webkit-box !important;
    -webkit-line-clamp: 4;
    margin-top: 12px; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.brands .title {
  position: sticky;
  top: 70px;
  height: auto;
  max-height: calc(100vh - 94px); }
  .brands .title .h2 {
    margin-bottom: 40px; }
  .brands .title p {
    width: 75%; }

.brands .thumbs a {
  display: block; }
  .brands .thumbs a:first-of-type {
    border-top: 1px solid #15191D; }

@media (max-width: 992px) {
  .brands .title {
    position: static; }
    .brands .title .h2 {
      margin-bottom: 40px; }
    .brands .title p {
      width: 100%;
      margin-bottom: 40px; }
  .hide-title .title {
    display: none !important; }
  .hide-title .thumbs {
    margin-top: 1px; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.thumb-wrapper {
  padding: 40px 0;
  border-bottom: 1px solid #15191D; }
  .thumb-wrapper .left .brand-logo {
    max-height: 60px;
    max-width: 50%;
    mix-blend-mode: luminosity;
    opacity: 0.5; }
  .thumb-wrapper .right p {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden; }
  .thumb-wrapper .right .button {
    height: 0;
    margin: 0;
    opacity: 0; }
  .thumb-wrapper:hover .left .brand-logo {
    mix-blend-mode: normal;
    opacity: 1; }
  .thumb-wrapper:hover .right .button {
    height: auto;
    opacity: 1;
    margin-top: 40px; }

.thumb-wrapper,
.left,
.brand-logo,
.right,
.button,
.thumb-wrapper:hover,
.thumb-wrapper:hover .thumb-wrapper,
.thumb-wrapper:hover .left,
.thumb-wrapper:hover .brand-logo,
.thumb-wrapper:hover .right,
.thumb-wrapper:hover .button {
  -webkit-transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: 0.6s cubic-bezier(0.215, 0.61, 0.355, 1); }

@media (max-width: 992px) {
  .thumb-wrapper {
    padding: 12px 0 !important; }
    .thumb-wrapper .left .brand-logo {
      mix-blend-mode: normal !important;
      opacity: 1 !important; }
    .thumb-wrapper .right .button {
      height: auto !important;
      margin-top: 20px !important;
      opacity: 1 !important; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.showroom .content-wrapper .content {
  height: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  border-top: 1px solid #15191D;
  border-bottom: 1px solid #15191D;
  justify-content: space-between; }
  .showroom .content-wrapper .content .h2 {
    margin-bottom: 80px; }
  .showroom .content-wrapper .content .bottom-content p {
    margin-bottom: 80px; }

.showroom .showroom-preview-wrapper div {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden; }
  .showroom .showroom-preview-wrapper div .showroom-preview {
    position: absolute;
    height: 100%;
    width: 100%; }

@media (max-width: 992px) {
  .showroom {
    min-height: 300px; }
    .showroom .content-wrapper .h2 {
      margin-bottom: 40px; }
    .showroom .content-wrapper .content {
      padding-top: 20px;
      padding-bottom: 20px; }
      .showroom .content-wrapper .content .bottom-content p {
        margin-bottom: 40px; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.form {
  scroll-margin-top: 70px; }
  .form .form-img-wrapper div {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden; }
    .form .form-img-wrapper div .form-img {
      position: absolute;
      height: 100%;
      width: 100%; }
  .form .content-wrapper .h2 {
    margin-bottom: 80px; }
  .form .content-wrapper .content {
    padding-top: 40px;
    padding-bottom: 40px;
    border-top: 1px solid #15191D;
    border-bottom: 1px solid #15191D;
    justify-content: space-between; }

@media (max-width: 992px) {
  .form {
    min-height: 0;
    scroll-margin-top: 54px; }
    .form .content-wrapper .h2 {
      margin-bottom: 40px; }
    .form .content-wrapper .content {
      min-height: 0;
      padding-top: 20px;
      padding-bottom: 20px; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

input,
textarea {
  padding: 20px 24px !important;
  margin-bottom: 24px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  font-family: "Maax";
  border: 1px solid #15191D !important;
  border-radius: 0 !important; }
  input:focus,
  textarea:focus {
    box-shadow: none !important;
    border-color: #8CB5E2 !important; }

label {
  font-size: 13px;
  line-height: 13px;
  font-weight: 400;
  font-family: "Maax"; }
  label a {
    text-decoration: underline; }

.form-check {
  padding: 12px 0 0 0;
  display: flex;
  align-items: flex-start; }

.checkbox {
  margin: 0 12px 0 0 !important;
  padding: 0 !important;
  height: 16px;
  width: 16px;
  background-color: #FFFFFF; }
  .checkbox:checked {
    background-color: #15191D !important; }

.form-check-label {
  padding-top: 3px; }

button {
  margin-top: 80px !important;
  margin-left: 12px !important;
  text-align: left;
  background-color: transparent; }

@media (max-width: 992px) {
  input,
  textarea {
    padding: 20px 24px !important;
    margin-bottom: 24px;
    font-size: 13px !important;
    line-height: 20px;
    margin-bottom: 12px; }
  button {
    margin-top: 40px !important;
    margin-left: 6px !important; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.newsletter {
  margin-bottom: 160px; }
  .newsletter .h2 {
    margin-bottom: 20px; }
  .newsletter .disclaimer {
    margin-bottom: 80px; }

@media (max-width: 992px) {
  .newsletter {
    margin-bottom: 80px; }
    .newsletter .h2 {
      margin-bottom: 12px; }
    .newsletter .disclaimer {
      margin-bottom: 40px; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.consulting-top {
  height: calc(100vh - 70px);
  max-height: 1080px;
  display: flex;
  flex-direction: column; }
  .consulting-top .consulting-img {
    height: 100px;
    flex-grow: 1; }
  .consulting-top .h1 {
    margin: 40px 0 40px 0; }

@media (max-width: 992px) {
  .consulting-top {
    height: calc(100vh - 54px);
    max-height: none; }
    .consulting-top .h1 {
      margin: 20px 0 20px 0; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.consulting {
  margin-top: -40px; }
  .consulting .h3 {
    margin-bottom: 20px; }

@media (max-width: 992px) {
  .consulting {
    margin-top: 0; }
    .consulting .consulting-content {
      margin-bottom: 40px; }
    .consulting div:nth-last-child(2) {
      margin-bottom: 0; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.showroom-top {
  height: calc(100vh - 70px);
  max-height: 1080px;
  display: flex;
  flex-direction: column; }
  .showroom-top .showroom-img-wrapper {
    height: 100px;
    flex-grow: 1; }
    .showroom-top .showroom-img-wrapper .showroom-img {
      height: 100%; }
  .showroom-top .h1 {
    margin: 40px 0 40px 0; }

@media (max-width: 992px) {
  .showroom-top {
    height: calc(100vh - 54px);
    max-height: none; }
    .showroom-top .showroom-img-wrapper img {
      height: 50px !important;
      flex-grow: 1;
      margin-top: 12px; }
      .showroom-top .showroom-img-wrapper img:first-child {
        margin-top: 0; }
    .showroom-top .h1 {
      margin: 20px 0 20px 0; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.showroom-description {
  margin-top: -40px; }
  .showroom-description .showroom-description-content .h3 {
    margin-bottom: 40px;
    line-height: 68px;
    max-width: 1920px; }
  .showroom-description .showroom-description-content .paragraph {
    margin-bottom: 80px; }
  .showroom-description span {
    letter-spacing: -0.160em; }

@media (max-width: 992px) {
  .showroom-description {
    margin-top: 0; }
    .showroom-description .showroom-description-content .h3 {
      margin-bottom: 20px;
      line-height: 45px; }
    .showroom-description .showroom-description-content .paragraph {
      margin-bottom: 40px; }
    .showroom-description .opening {
      font-size: 20px; }
      .showroom-description .opening span {
        letter-spacing: -0.160em; }
    .showroom-description a {
      margin-top: 40px;
      font-size: 20px; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.single-top .left-center {
  flex-grow: 1; }
  .single-top .left-center .content-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 94px); }
    .single-top .left-center .content-left .top-content .single-heading {
      margin-top: 80px;
      margin-bottom: 20px; }
      .single-top .left-center .content-left .top-content .single-heading .brand-logo {
        height: 40px;
        max-width: 50%;
        margin-bottom: 40px; }
    .single-top .left-center .content-left .top-content .description {
      width: 75%;
      margin-top: 80px; }
    .single-top .left-center .content-left .button {
      margin-top: 80px; }
  .single-top .left-center .content-center {
    position: sticky;
    top: 70px;
    min-height: calc(100vh - 94px);
    height: calc(100vh - 94px); }
    .single-top .left-center .content-center .single-img-wrapper {
      height: 100%;
      width: 100%;
      position: relative;
      overflow: hidden; }
      .single-top .left-center .content-center .single-img-wrapper .single-img {
        position: absolute;
        height: 100%;
        width: 100%; }

.single-top .content-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  top: 70px;
  min-height: calc(100vh - 94px); }
  .single-top .content-right .gallery .single-img-thumbnail {
    height: calc(8.3vw - 24px);
    margin-bottom: 24px;
    cursor: pointer;
    opacity: 0.5; }
    .single-top .content-right .gallery .single-img-thumbnail:hover {
      opacity: 1; }
  .single-top .content-right .gallery .active {
    opacity: 1; }

@media (min-width: 993px) and (min-height: 66vw) {
  .single-top .content-center {
    min-height: 0 !important;
    max-height: 50vw !important; } }

@media (max-width: 992px) {
  .single-top {
    display: flex;
    align-content: flex-start; }
    .single-top .left-center {
      display: flex;
      flex-direction: column;
      min-height: calc(100vh - 66px) !important; }
      .single-top .left-center .content-left {
        min-height: 0;
        justify-content: space-between;
        flex-grow: 1; }
        .single-top .left-center .content-left .top-content .single-heading {
          margin-top: 40px;
          margin-bottom: 12px; }
          .single-top .left-center .content-left .top-content .single-heading .brand-logo {
            height: 30px;
            margin-bottom: 20px; }
        .single-top .left-center .content-left .top-content .description {
          width: 100%;
          margin-top: 40px; }
        .single-top .left-center .content-left .button {
          margin-top: 40px; }
      .single-top .left-center .content-center {
        position: static;
        min-height: 200px;
        height: calc(75vw - 24px);
        max-height: 50vh;
        margin-bottom: 20px; }
    .single-top .content-right {
      top: 54px;
      min-height: calc(100vh - 66px);
      height: calc(100vh - 66px);
      order: -1; }
      .single-top .content-right .gallery {
        margin-bottom: 28px; }
        .single-top .content-right .gallery .single-img-thumbnail {
          height: calc(12.5vw - 12px);
          margin-bottom: 12px; }
      .single-top .content-right .social-buttons {
        position: absolute;
        bottom: 0; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.breadcrumb-wrapper nav .breadcrumb {
  display: flex;
  flex-direction: column; }
  .breadcrumb-wrapper nav .breadcrumb .breadcrumb-item {
    padding: 0; }
    .breadcrumb-wrapper nav .breadcrumb .breadcrumb-item:before {
      content: none; }
    .breadcrumb-wrapper nav .breadcrumb .breadcrumb-item:after {
      content: ' >';
      margin-right: 5px; }
    .breadcrumb-wrapper nav .breadcrumb .breadcrumb-item:hover, .breadcrumb-wrapper nav .breadcrumb .breadcrumb-item:active, .breadcrumb-wrapper nav .breadcrumb .breadcrumb-item:focus {
      text-decoration: underline; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.contacts .h2 {
  margin-bottom: 40px; }

.contacts .description {
  margin-bottom: 80px; }

.contacts .contacts-content .h3 {
  margin-bottom: 40px; }

.contacts .contacts-content a {
  display: block;
  margin-top: 20px; }

.contacts .map-wrapper .map {
  height: 50vh;
  max-height: 900px;
  background-color: #15191D;
  margin-top: 160px; }

@media (max-width: 992px) {
  .contacts .description {
    margin-bottom: 40px; }
  .contacts .contacts-content {
    margin-bottom: 40px; }
    .contacts .contacts-content .h3 {
      margin-bottom: 20px; }
    .contacts .contacts-content a {
      margin-top: 12px; }
  .contacts .map-wrapper .map {
    max-height: 300px;
    margin-top: 40px; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.catalogue {
  position: relative; }
  .catalogue .breadcrumb-outer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10; }
  .catalogue .aside-title {
    position: sticky;
    top: 70px;
    height: auto;
    max-height: calc(100vh - 94px); }
    .catalogue .aside-title .aside-title-bottom .h2 {
      margin-bottom: 40px; }
    .catalogue .aside-title .aside-title-bottom p {
      width: 75%; }
  .catalogue .section-cards {
    margin-bottom: -80px;
    flex-grow: 1; }

@media (max-width: 992px) {
  .catalogue .breadcrumb-outer {
    position: static;
    margin-top: 12px;
    margin-bottom: 40px; }
  .catalogue .aside-title {
    position: static;
    height: auto;
    max-height: none;
    display: flex !important;
    justify-content: flex-end !important; }
    .catalogue .aside-title .aside-title-bottom {
      position: sticky;
      bottom: 0;
      height: 0;
      width: auto;
      margin-bottom: -50px; }
      .catalogue .aside-title .aside-title-bottom .h2 {
        width: 75vh;
        transform-origin: top left;
        transform: rotate(-90deg) translateX(12px); } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.section-articles .h2 {
  margin-bottom: 40px; }

.section-articles .blog-description {
  margin-bottom: 80px;
  width: 50%; }

.section-articles .blog-tags {
  margin-bottom: 80px; }
  .section-articles .blog-tags p {
    margin-bottom: 20px; }
  .section-articles .blog-tags ul {
    overflow-x: auto;
    width: calc(100% + 24px);
    margin-left: -12px;
    padding-left: 12px; }
    .section-articles .blog-tags ul li {
      height: 40px;
      padding: 20px;
      border: 1px solid #15191D;
      margin-right: 12px;
      display: flex;
      align-items: center;
      cursor: pointer;
      opacity: 0.25; }
      .section-articles .blog-tags ul li:hover {
        opacity: 1; }
    .section-articles .blog-tags ul .active {
      opacity: 1;
      position: relative; }
      .section-articles .blog-tags ul .active:after {
        content: '';
        width: 15px;
        height: 1px;
        background-color: #15191D;
        margin-left: 10px;
        transform: rotate(45deg); }
      .section-articles .blog-tags ul .active:before {
        content: '';
        width: 15px;
        height: 1px;
        background-color: #15191D;
        margin-left: 10px;
        transform: rotate(-45deg) translate(-50%, -700%);
        position: absolute;
        right: 10px; }

.section-articles .section-cards {
  margin-bottom: -80px; }

@media (max-width: 992px) {
  .section-articles .blog-description {
    margin-bottom: 40px; }
  .section-articles .blog-tags {
    margin-bottom: 40px; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.article .article-top .article-img {
  height: 50vh;
  max-height: 900px; }

.article .article-title {
  margin: 40px 0; }
  .article .article-title .h2 {
    padding: 0 0 0 6px; }

.article .article-info .line-wrapper .line {
  border-top: 1px solid #15191D;
  margin-top: 20px; }

.article .article-end {
  margin-top: 40px; }

@media (max-width: 992px) {
  .article .article-top .article-img {
    max-height: 300px;
    margin-bottom: 20px; }
  .article .article-title {
    margin: 40px 0 12px 0; }
    .article .article-title .h2 {
      padding: 0 !important;
      font-size: 45px;
      line-height: 45px; }
  .article .article-info .tags-wrapper {
    margin-bottom: 40px; }
  .article .article-end .next {
    margin-top: 40px; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.tags ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .tags ul .tag-label {
    margin-right: 10px; }
  .tags ul .tag {
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid #15191D; }
    .tags ul .tag:last-child {
      padding-right: 0;
      margin-right: 0;
      border-right: none; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.article-paragraph {
  padding-top: 40px; }
  .article-paragraph .paragraph-title .h3 {
    font-size: 30px;
    position: sticky;
    top: 70px;
    position: sticky; }
  .article-paragraph .line-wrapper .line {
    border-top: 1px solid #15191D;
    margin-top: 40px; }

@media (max-width: 992px) {
  .article-paragraph {
    padding-top: 20px; }
    .article-paragraph .paragraph-title .h3 {
      font-size: 30px;
      line-height: 30px;
      position: static;
      margin-bottom: 40px; }
    .article-paragraph p {
      font-size: 20px;
      line-height: 30px; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.social-buttons {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .social-buttons a {
    margin-left: 10px; }
    .social-buttons a i {
      font-size: 16px; }

@media (max-width: 992px) {
  .vertical-social-buttons {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start; }
    .vertical-social-buttons p {
      display: none; }
    .vertical-social-buttons a {
      margin-left: 0;
      margin-top: 40px; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.pagination {
  margin-top: 160px; }
  .pagination a:nth-last-child(2) {
    margin-right: 40px; }
  .pagination .disabled {
    pointer-events: none;
    opacity: 0.25; }
  .pagination .current {
    pointer-events: none;
    text-decoration: none; }
  .pagination .previous {
    margin-right: 40px; }
  .pagination .page {
    margin-right: 20px; }
  .pagination .next {
    margin-right: 0; }

@media (max-width: 992px) {
  .pagination {
    margin-top: 80px;
    padding-top: 40px; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.about-page .about-section {
  margin-bottom: 320px; }
  .about-page .about-section .inner .top-wrapper {
    height: calc(100vh - 70px);
    max-height: 1080px;
    display: flex;
    flex-direction: column; }
    .about-page .about-section .inner .top-wrapper .top-img-wrapper {
      height: 100px;
      flex-grow: 1; }
      .about-page .about-section .inner .top-wrapper .top-img-wrapper img {
        height: 100%;
        width: 100%; }
    .about-page .about-section .inner .top-wrapper .h2 {
      margin: 40px 0 40px 0; }
  .about-page .about-section .inner .sticky {
    position: sticky;
    top: 70px; }
  .about-page .about-section .inner .h3 {
    margin-bottom: 20px; }
  .about-page .about-section .inner .text {
    margin-bottom: 80px; }
  .about-page .about-section .fake {
    margin-bottom: -56px; }
    .about-page .about-section .fake .top-wrapper {
      margin-bottom: 296px; }
  .about-page .about-section .people {
    margin-bottom: 24px; }
  .about-page .about-section .logos {
    margin-bottom: -136px; }
    .about-page .about-section .logos .client-logo {
      height: 40px;
      margin-bottom: 160px; }
      .about-page .about-section .logos .client-logo img {
        width: 50%;
        max-width: 200px;
        height: auto; }

.about-page .h1 {
  margin: 40px 0 40px 0; }

@media (max-width: 992px) {
  .about-page .about-section {
    margin-bottom: 160px; }
    .about-page .about-section .inner .top-wrapper {
      height: calc(100vh - 54px);
      max-height: none; }
      .about-page .about-section .inner .top-wrapper .h2 {
        margin: 20px 0 20px 0;
        font-size: 45px;
        line-height: 45px; }
    .about-page .about-section .inner .sticky {
      position: static; }
    .about-page .about-section .inner .h3 {
      margin-bottom: 20px; }
    .about-page .about-section .inner .text {
      margin-bottom: 40px; }
    .about-page .about-section .content {
      margin-top: 80px; }
    .about-page .about-section .people {
      margin-bottom: 0; }
    .about-page .about-section .logos {
      margin-bottom: -80px; }
      .about-page .about-section .logos .client-logo {
        height: 30px;
        margin-bottom: 80px; }
        .about-page .about-section .logos .client-logo img {
          width: 50%;
          max-width: 100px;
          height: auto; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.person {
  height: calc(16.6vw - 24px); }
  .person .person-img {
    height: 100%; }
  .person .person-info p {
    margin-top: 20px; }

@media (max-width: 992px) {
  .person {
    height: calc(50vw - 12px);
    margin-bottom: 12px; }
    .person .person-info p {
      margin-top: 12px; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.menu-modal {
  background-color: #FFFFFF;
  padding-bottom: 34px; }
  .menu-modal .left,
  .menu-modal .right {
    margin-top: -25px; }
    .menu-modal .left .menu-section,
    .menu-modal .right .menu-section {
      margin-top: 40px; }
      .menu-modal .left .menu-section .menu-section-name,
      .menu-modal .right .menu-section .menu-section-name {
        font-size: 20px;
        margin-bottom: 20px;
        display: block; }
      .menu-modal .left .menu-section ul a,
      .menu-modal .right .menu-section ul a {
        margin-bottom: 6px !important;
        display: block; }
      .menu-modal .left .menu-section a:active, .menu-modal .left .menu-section a:focus,
      .menu-modal .right .menu-section a:active,
      .menu-modal .right .menu-section a:focus {
        text-decoration: underline; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.not-found {
  height: calc(100vh - 70px);
  max-height: 1080px;
  display: flex;
  flex-direction: column; }
  .not-found .not-found-img {
    object-fit: bottom;
    height: 100px;
    flex-grow: 1; }
  .not-found .h1 {
    margin: 40px 0 20px 0; }
  .not-found .h3 {
    line-height: 68px;
    max-width: 1920px; }
  .not-found a {
    margin: 40px 0 40px 0; }

@media (max-width: 992px) {
  .not-found {
    height: calc(100vh - 54px);
    max-height: none; }
    .not-found .h1 {
      margin: 20px 0 20px 0; }
    .not-found .h3 {
      line-height: 45px; }
    .not-found a {
      margin-bottom: 20px; } }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.zaux-theme-light1.header {
  color: #FFFFFF;
  background-color: #FFFFFF; }

header .zaux-theme-dark1.header {
  color: #FFFFFF;
  background-color: #d9534f; }
  header .zaux-theme-dark1.header a:link, header .zaux-theme-dark1.header a:visited {
    color: #FFFFFF;
    text-decoration: none; }
  header .zaux-theme-dark1.header a:hover, header .zaux-theme-dark1.header a:active {
    color: #FFFFFF;
    text-decoration: underline; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (max-width: 992px) {
  .row,
  .col {
    --bs-gutter-x: 12px; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
