@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("section-brands");

.brands {

    .title {
        position: sticky;
        top: 70px;
        height: auto;
        max-height: calc(100vh - 94px);

        .h2 {
            margin-bottom: 40px;
        }

        p {
            width: 75%;
        }

    }

    .thumbs {

        a {
            display: block;

            &:first-of-type {
                border-top: 1px solid $zaux-color-default-black;
            }

        }

    }

}

@media (max-width: $breakpoint-medium) {

    .brands {

        .title {
            position: static;

            .h2 {
                margin-bottom: 40px;
            }

            p {
                width: 100%;
                margin-bottom: 40px;
            }

        }

    }

    .hide-title {

        .title {
            display: none !important;
        }

        .thumbs {
            margin-top: 1px;
        }

    }

}