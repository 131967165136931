@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("social-buttons");


.social-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
        margin-left: 10px;

        i {
            font-size: 16px;
        }

    }

}

@media (max-width: $breakpoint-medium) {

    .vertical-social-buttons {
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;

        p {
            display: none;
        }

        a {
            margin-left: 0;
            margin-top: 40px;
        }

    }

}