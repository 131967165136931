@charset "UTF-8";

@mixin appearance($value) {
  -webkit-appearance: $value;
  -moz-appearance: $value;
  appearance: $value;
}

// @see https://css-tricks.com/styling-underlines-web/
@mixin underline($text_color, $bg_color) {
  text-shadow: -2px -2px $bg_color, -2px 2px $bg_color, 2px -2px $bg_color, 2px 2px $bg_color;
  box-shadow: inset 0 -0.175em $bg_color, inset 0 -0.2em $text_color;
}

// @see https://sharkcoder.com/visual/underline
@mixin underline-via-bg($color, $sizePx, $yPos: 100%) {
  background-image: linear-gradient(to right, $color 0%, $color $yPos);
  background-repeat: repeat-x;
  background-position: 0 $yPos;
  background-size: $yPos $sizePx;
}

// Object-fit mixin who supports the polyfill's syntax
// https://github.com/fregante/object-fit-images
@mixin objectfit($type: 'cover', $position: 'center') {
  object-fit: $type;
  object-position: $position;
  font-family: 'object-fit: #{$type}; object-position: #{$position};';
}

// Fix SVG in <img> tags not scaling in IE9, IE10, IE11
// IE9, IE10, and IE11 don't properly scale SVG files added with img tags when viewBox, width
// and height attributes are specified. To get more consistent scaling across browsers always ensure
// you specify a viewBox but leave off the width and height attributes on your svg element.
//
// @see https://gist.github.com/larrybotha/7881691
// @see https://stackoverflow.com/questions/9777143/svg-in-img-element-proportions-not-respected-in-ie9/9792254#9792254
@mixin onIE() {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

@mixin placeholder() {
  &::placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin cancel-style() {
  &::-ms-clear {
    @content;
  }
  &::-webkit-search-cancel-button {
    @content;
  }
}

// https://css-tricks.com/snippets/sass/custom-scrollbars-mixin/
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%), $rounded:4px) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width:  $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: $rounded;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }

  // FF
  scrollbar-color: $foreground-color $background-color !important;
  scrollbar-width: thin !important;
}
