@charset "UTF-8";

$zaux-color-default-white: #FFFFFF;
$zaux-color-default-black: #15191D;
$zaux-color-default-red: #d9534f;
$zaux-color-default-green: #5cb85c;
$zaux-color-default-blue: #8CB5E2;
$zaux-color-default-magenta: #FF00FF;
$zaux-color-default-orange: #f0ad4e;

$zaux-color-set-default: (
	"white": #FFFFFF,
	"black": #15191D,
	"red": #d9534f,
	"green": #5cb85c,
	"blue": #8CB5E2,
	"magenta": #FF00FF,
	"orange": #f0ad4e,
);

$zaux-color-app-theme: #CD2D45;
$zaux-color-app-theme2: yellow;

$zaux-color-set-app: (
	"theme": #CD2D45,
	"theme2": yellow,
);

