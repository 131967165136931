@charset "UTF-8";

//Layout

.zaux-svg-fluid-wrapper {
  display: block;
  line-height: 0;

  svg {
    max-width: 100%;
  }
}

:root:not(.ie-legacy) {
  .zaux-svg-fluid-wrapper {
    svg {
      height: auto;
    }
  }
}

.container {
  padding: 0 24px;
  margin: 0 auto;
  max-width: none;
}

.section {
  margin: 160px auto 320px auto;
}

.section-first {
  margin-bottom: 320px;
}

.top {
  padding-top: $zaux-header-height;
}

@media (max-width: $breakpoint-medium) {

  .container {
    padding: 0 12px;
    overflow: hidden;
  }

  .section {
    margin: 80px auto 160px auto;
  }

  .section-first {
    margin-bottom: 160px;
  }

  .top {
    padding-top: $zaux-header-height-mobile;
  }

}



//Generals

.section-title {
  margin-bottom: 80px;
}

.card-title {
  text-transform: uppercase;
}

.cards-large-description a .card .card-content p {
  width: 75%;
}

.vh-fit {
  height: $vh-fit-height;
  max-height: $vh-fit-height;
}

.button {
  text-decoration: underline;
  padding: 0;
  border: none;
  color: $zaux-color-default-black;

  &:after {
    content: ' >';
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;

    &:after {
      content: '  >';
      white-space: pre;
    }

  }

}

.link {
  text-decoration: underline;
  color: $zaux-color-default-black;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none !important;
  }

}

.hide {
  display: none;
}

.default-transition {
  transition: all 0.5s ease-out;
}

@media (max-width: $breakpoint-medium) {

  .section-title {
    margin-bottom: 40px;
  }

  .big-button {
    font-size: $zaux-text-big-size;
  }

}



//Unique elements

.home-top {
  height: calc(100vh - 289px) !important;
}

@media (max-width: $breakpoint-medium) {

  .home-top {
    height: calc(100vh - 249px) !important;
  }

}


//Responsive layout

@media (min-width: #{$breakpoint-medium + 1}) {

  .mobile-only {
    display: none !important;
  }

}

@media (max-width: $breakpoint-medium) {

  .desktop-only {
    display: none !important;
  }

  .medium-full {
    width: 100% !important;
  }

  .medium-75 {
    width: 75% !important;
  }

  .medium-50 {
    width: 50% !important;
  }

  .medium-25 {
    width: 25% !important;
  }

  .medium-column {
    display: flex;
    flex-direction: column;
  }

  .medium-row {
    display: flex;
    flex-direction: row !important;
  }

  .medium-priority {
    display: flex;
    order: -1;
  }

  .responsive-width {
    max-width: 600px !important;
  }

  .responsive-overflow {
    overflow: visible;
  }

  .medium-truncate {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

}