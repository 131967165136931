@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("footer");


footer {
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;

  .footer-info {
    margin: 141px -12px 20px -12px;

    ul {

      .zaux-text-big {
        padding-bottom: 20px;
      }

    }

  }

  .footer-img {
    flex-grow: 1;
    height: 50px;
  }

  .socket {
    height: 40px;

    a,
    .zaki-sign {
      height: 40px;
    }

  }

}


@media (min-width: #{$breakpoint-medium + 1}) {

  @media (max-height: 700px) {

    footer {

      .footer-info {
        margin-top: 21px;
      }

    }

  }

}


@media (max-width: $breakpoint-medium) {

  footer {
    height: calc(100vh - 54px);
    display: flex;
    flex-direction: column;

    .footer-info {
      margin-top: 20px;

      ul {
        padding-bottom: 40px;

        .zaux-text-big {
          padding-bottom: 20px;
          font-size: 20px;
        }

        li {

          a {
            margin-top: 6px;
            display: block;
          }

        }

        &:last-child {
          padding-bottom: 0;
        }

      }

    }

  }

}