@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("thumb-brand");

.thumb-wrapper {
    padding: 40px 0;
    border-bottom: 1px solid $zaux-color-default-black;

    .left {

        .brand-logo {
            max-height: 60px;
            max-width: 50%;
            mix-blend-mode: luminosity;
            opacity: 0.5;
        }

    }

    .right {

        p {
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .button {
            height: 0;
            margin: 0;
            opacity: 0;
        }

    }

    &:hover {

        .left {

            .brand-logo {
                mix-blend-mode: normal;
                opacity: 1;
            }

        }

        .right {

            .button {
                height: auto;
                opacity: 1;
                margin-top: 40px;
            }

        }

    }

}



.thumb-wrapper,
.left,
.brand-logo,
.right,
.button,
.thumb-wrapper:hover,
.thumb-wrapper:hover .thumb-wrapper,
.thumb-wrapper:hover .left,
.thumb-wrapper:hover .brand-logo,
.thumb-wrapper:hover .right,
.thumb-wrapper:hover .button {
    -webkit-transition: $zaux-transition-1;
    -moz-transition: $zaux-transition-1;
    -o-transition: $zaux-transition-1;
    transition: $zaux-transition-1;
}



@media (max-width: $breakpoint-medium) {

    .thumb-wrapper {
        padding: 12px 0 !important;

        .left {

            .brand-logo {
                mix-blend-mode: normal !important;
                opacity: 1 !important;
            }

        }

        .right {

            .button {
                height: auto !important;
                margin-top: 20px !important;
                opacity: 1 !important;
            }

        }

    }

}