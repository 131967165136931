@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("contacts-top");

.contacts {

    .h2 {
        margin-bottom: 40px;
    }

    .description {
        margin-bottom: 80px;
    }

    .contacts-content {

        .h3 {
            margin-bottom: 40px;
        }

        a {
            display: block;
            margin-top: 20px;
        }

    }

    .map-wrapper {

        .map {
            height: 50vh;
            max-height: 900px;
            background-color: $zaux-color-default-black;
            margin-top: 160px;
        }

    }

}



@media (max-width: $breakpoint-medium) {

    .contacts {

        .description {
            margin-bottom: 40px;
        }

        .contacts-content {
            margin-bottom: 40px;

            .h3 {
                margin-bottom: 20px;
            }

            a {
                margin-top: 12px;
            }

        }

        .map-wrapper {

            .map {
                max-height: 300px;
                margin-top: 40px;
            }

        }

    }

}