@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("single-top");

.single-top {

    .left-center {
        flex-grow: 1;

        .content-left {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: calc(100vh - 94px);

            .top-content {

                .single-heading {
                    margin-top: 80px;
                    margin-bottom: 20px;

                    .brand-logo {
                        height: 40px;
                        max-width: 50%;
                        margin-bottom: 40px;
                    }

                }

                .description {
                    width: 75%;
                    margin-top: 80px;
                }

            }

            .button {
                margin-top: 80px;
            }

        }

        .content-center {
            position: sticky;
            top: $zaux-header-height;
            min-height: calc(100vh - 94px);
            height: calc(100vh - 94px);

            .single-img-wrapper {
                height: 100%;
                width: 100%;
                position: relative;
                overflow: hidden;

                .single-img {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                }

            }

        }

    }

    .content-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: sticky;
        top: $zaux-header-height;
        min-height: calc(100vh - 94px);

        .gallery {

            .single-img-thumbnail {
                height: calc(8.3vw - 24px);
                margin-bottom: 24px;
                cursor: pointer;
                opacity: 0.5;

                &:hover {
                    opacity: 1;
                }

            }

            .active {
                opacity: 1;
            }

        }

    }

}



@media (min-width: #{$breakpoint-medium + 1}) {

    @media (min-height: 66vw) {

        .single-top {

            .content-center {
                min-height: 0 !important;
                max-height: 50vw !important;
            }

        }

    }

}

@media (max-width: $breakpoint-medium) {

    .single-top {
        display: flex;
        align-content: flex-start;

        .left-center {
            display: flex;
            flex-direction: column;
            min-height: calc(100vh - 66px) !important;

            .content-left {
                min-height: 0;
                justify-content: space-between;
                flex-grow: 1;

                .top-content {

                    .single-heading {
                        margin-top: 40px;
                        margin-bottom: 12px;

                        .brand-logo {
                            height: 30px;
                            margin-bottom: 20px;
                        }

                    }

                    .description {
                        width: 100%;
                        margin-top: 40px;
                    }

                }

                .button {
                    margin-top: 40px;
                }

            }

            .content-center {
                position: static;
                min-height: 200px;
                height: calc(75vw - 24px);
                max-height: 50vh;
                margin-bottom: 20px;
            }

        }

        .content-right {
            top: $zaux-header-height-mobile;
            min-height: calc(100vh - 66px);
            height: calc(100vh - 66px);
            order: -1;

            .gallery {
                margin-bottom: 28px;

                .single-img-thumbnail {
                    height: calc(12.5vw - 12px);
                    margin-bottom: 12px;
                }

            }

            .social-buttons {
                position: absolute;
                bottom: 0;
            }

        }

    }

}