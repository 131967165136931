@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("menu");

.menu-modal {
    background-color: $zaux-color-default-white;
    padding-bottom: 34px;

    .left,
    .right {
        margin-top: -25px;

        .menu-section {
            margin-top: 40px;

            .menu-section-name {
                font-size: 20px;
                margin-bottom: 20px;
                display: block;
            }

            ul {

                a {
                    margin-bottom: 6px !important;
                    display: block;
                }

            }

            a {

                &:active,
                &:focus {
                    text-decoration: underline;
                }

            }

        }

    }

}