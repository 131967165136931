$zaux-main-title-big-size: 102px;
$zaux-main-title-medium-size: 68px;
$zaux-main-title-small-size: 45px;
$zaux-text-big-size: 20px;
$zaux-text-small-size: 13px;

@media (max-width: $breakpoint-medium) {
    $zaux-main-title-big-size: 45px;
    $zaux-main-title-medium-size: 30px;
    $zaux-main-title-small-size: 30px;
    $zaux-text-big-size: 13px;
}

.zaux-main-title {
    font-family: $zaux-font1;

    &-big {
        font-size: $zaux-main-title-big-size;
        line-height: $zaux-main-title-big-size;
        letter-spacing: $title-letter-spacing;
        font-weight: 300;
        font-family: $zaux-font1;
    }

    &-big-bold {
        font-size: $zaux-main-title-big-size;
        line-height: $zaux-main-title-big-size;
        letter-spacing: $title-letter-spacing;
        font-weight: 700;
        font-family: $zaux-font1;
    }

    &-medium {
        font-size: $zaux-main-title-medium-size;
        line-height: $zaux-main-title-medium-size;
        letter-spacing: $title-letter-spacing;
        font-weight: 300;
        font-family: $zaux-font1;
    }

    &-medium-bold {
        font-size: $zaux-main-title-medium-size;
        line-height: $zaux-main-title-medium-size;
        letter-spacing: $title-letter-spacing;
        font-weight: 700;
        font-family: $zaux-font1;
    }

    &-small {
        font-size: $zaux-main-title-small-size;
        line-height: $zaux-main-title-small-size;
        letter-spacing: $title-letter-spacing;
        font-weight: 300;
        font-family: $zaux-font1;
    }

    &-small-bold {
        font-size: $zaux-main-title-small-size;
        line-height: $zaux-main-title-small-size;
        letter-spacing: $title-letter-spacing;
        font-weight: 700;
        font-family: $zaux-font1;
    }
}

.zaux-text {

    &-big {
        font-size: $zaux-text-big-size;
        line-height: 30px;
        font-weight: 400;
        font-family: $zaux-font2;
    }

    &-big-bold {
        font-size: $zaux-text-big-size;
        line-height: 30px;
        font-weight: 700;
        font-family: $zaux-font2;
    }

    &-small {
        font-size: $zaux-text-small-size;
        line-height: $zaux-text-big-size;
        font-weight: 400;
        font-family: $zaux-font2;
    }

    &-small-bold {
        font-size: $zaux-text-small-size;
        line-height: $zaux-text-big-size;
        font-weight: 700;
        font-family: $zaux-font2;
    }
}



@media (max-width: $breakpoint-medium) {
    .zaux-main-title {
        font-family: $zaux-font1;

        &-big,
        &-big-bold {
            font-size: $zaux-main-title-small-size;
            line-height: $zaux-main-title-small-size;
        }

        &-medium,
        &-medium-bold,
        &-small,
        &-small-bold {
            font-size: 30px;
            line-height: 30px;
        }
    }

    .zaux-text {

        &-big,
        &-big-bold {
            font-size: $zaux-text-small-size;
            line-height: $zaux-text-big-size;
        }
    }
}