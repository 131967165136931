@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("tags");


.tags {

    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .tag-label {
            margin-right: 10px;
        }

        .tag {
            padding-right: 10px;
            margin-right: 10px;
            border-right: 1px solid $zaux-color-default-black;

            &:last-child {
                padding-right: 0;
                margin-right: 0;
                border-right: none;
            }

        }

    }

}