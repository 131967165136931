@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("highlights");

.highlights {

    a {
        height: 100%;

        .single-highlight {
            align-content: space-between;
            overflow: hidden;
            max-height: 900px;

            .highlight-img {
                flex-grow: 1;
                height: 100px;
                transform: translateZ(0);
            }

            .highlight-content {
                position: relative;

                .h3,
                p {
                    margin-top: 20px;
                }

                .description {
                    width: 50%;
                }

                .button {
                    height: 0;
                    margin: 0;
                    opacity: 0;
                }

            }

        }

        &:nth-child(2) {

            .h3,
            p {
                margin-top: 0 !important;
                margin-bottom: 20px;
            }

            h3 {
                padding-top: 2px;
            }

        }

        &:hover {

            .single-highlight {

                .highlight-content {

                    .h3,
                    p {
                        margin-top: 40px;
                    }

                    .button {
                        height: auto;
                        opacity: 1;
                    }

                }

            }

        }

        &:nth-child(2):hover {

            .h3,
            p,
            .button {
                margin-bottom: 40px;
            }

        }

    }

}

a,
a:hover,
.highlights,
a:hover .highlights,
.single-highlight,
a:hover .single-highlight,
.highlight-img,
a:hover .highlight-img,
.highlight-content,
a:hover .highlight-content,
.h3,
a:hover .h3,
p,
a:hover p,
.button,
a:hover .button {
    -webkit-transition: $zaux-transition-1;
    -moz-transition: $zaux-transition-1;
    -o-transition: $zaux-transition-1;
    transition: $zaux-transition-1;
}



@media (max-width: $breakpoint-medium) {

    .highlights {

        a {
            margin-bottom: 80px;

            .single-highlight {
                height: 450px;

                .highlight-content {

                    .h3,
                    p {
                        margin-top: 20px !important;
                        padding-top: 0%;
                    }

                    .button {
                        height: auto;
                        opacity: 1;
                        margin-top: 20px;
                    }

                }

            }

            &:last-of-type {
                margin-bottom: 0;
            }

        }

    }

}