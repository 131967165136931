@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("header");

.zaux-theme-light1.#{$component} {
  color: $zaux-color-default-white;
  background-color: $zaux-color-default-white;

}

header {

  .zaux-theme-dark1.#{$component} {
    color: $zaux-color-default-white;
    background-color: $zaux-color-default-red;

    a {

      &:link,
      &:visited {
        color: $zaux-color-default-white;
        text-decoration: none;
      }

      &:hover,
      &:active {
        color: $zaux-color-default-white;
        text-decoration: underline;
      }
    }
  }
}