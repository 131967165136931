@charset "UTF-8";

//////////////////////////////////////////////////
// Fonts
//////////////////////////////////////////////////

$zaux-fonts-path: "../fonts" !default;

/* Fonts inclusions */

//Font 1

@font-face {
    font-family: $zaux-font1;
    src: url('#{$zaux-fonts-path}/#{$zaux-font1-lc}/#{$zaux-font1-lc}-light.woff2') format('woff2'),
    url('#{$zaux-fonts-path}/#{$zaux-font1-lc}/#{$zaux-font1-lc}-light.woff') format('woff');
    font-weight: $zaux-typo-fw-light;
}

@font-face {
    font-family: $zaux-font1;
    src: url('#{$zaux-fonts-path}/#{$zaux-font1-lc}/#{$zaux-font1-lc}-light-italic.woff2') format('woff2'),
    url('#{$zaux-fonts-path}/#{$zaux-font1-lc}/#{$zaux-font1-lc}-light-italic.woff') format('woff');
    font-style: italic;
    font-weight: $zaux-typo-fw-light;
}

/*
@font-face {
    font-family: $zaux-font1;
    src: url('#{$zaux-fonts-path}/#{$zaux-font1-lc}/#{$zaux-font1-lc}-medium.woff2') format('woff2'),
    url('#{$zaux-fonts-path}/#{$zaux-font1-lc}/#{$zaux-font1-lc}-medium.woff') format('woff');
    font-weight: $zaux-typo-fw-medium;
}
*/


/*
@font-face {
    font-family: $zaux-font1;
    src: url('#{$zaux-fonts-path}/#{$zaux-font1-lc}/#{$zaux-font1-lc}-medium-italic.woff2') format('woff2'),
    url('#{$zaux-fonts-path}/#{$zaux-font1-lc}/#{$zaux-font1-lc}-medium-italic.woff') format('woff');
    font-style: italic;
    font-weight: $zaux-typo-fw-medium;
}


@font-face {
    font-family: $zaux-font1;
    src: url('#{$zaux-fonts-path}/#{$zaux-font1-lc}/#{$zaux-font1-lc}-italic.woff2') format('woff2'),
    url('#{$zaux-fonts-path}/#{$zaux-font1-lc}/#{$zaux-font1-lc}-italic.woff') format('woff');
    font-style: italic;
}
*/

@font-face {
    font-family: $zaux-font1;
    src: url('#{$zaux-fonts-path}/#{$zaux-font1-lc}/#{$zaux-font1-lc}-bold.woff2') format('woff2'),
    url('#{$zaux-fonts-path}/#{$zaux-font1-lc}/#{$zaux-font1-lc}-bold.woff') format('woff');
    font-weight: $zaux-typo-fw-bold;
}

@font-face {
    font-family: $zaux-font1;
    src: url('#{$zaux-fonts-path}/#{$zaux-font1-lc}/#{$zaux-font1-lc}-bold-italic.woff2') format('woff2'),
    url('#{$zaux-fonts-path}/#{$zaux-font1-lc}/#{$zaux-font1-lc}-bold-italic.woff') format('woff');
    font-style: italic;
    font-weight: $zaux-typo-fw-bold;
}

/*
@font-face {
    font-family: $zaux-font1;
    src: url('#{$zaux-fonts-path}/#{$zaux-font1-lc}/#{$zaux-font1-lc}-regular.woff2') format('woff2'),
    url('#{$zaux-fonts-path}/#{$zaux-font1-lc}/#{$zaux-font1-lc}-regular.woff') format('woff');
    font-weight: $zaux-typo-fw-regular;
}


@font-face {
    font-family: $zaux-font1;
    src: url('#{$zaux-fonts-path}/#{$zaux-font1-lc}/#{$zaux-font1-lc}-italic.woff2') format('woff2'),
    url('#{$zaux-fonts-path}/#{$zaux-font1-lc}/#{$zaux-font1-lc}-italic.woff') format('woff');
    font-style: italic;
    font-weight: $zaux-typo-fw-regular;
}
*/

/*-- Font 2 --*/

/*
@font-face {
    font-family: $zaux-font2;
    src: url('#{$zaux-fonts-path}/#{$zaux-font2-lc}/#{$zaux-font2-lc}-light.woff2') format('woff2'),
    url('#{$zaux-fonts-path}/#{$zaux-font2-lc}/#{$zaux-font2-lc}-light.woff') format('woff');
    font-weight: $zaux-typo-fw-light;
}

@font-face {
    font-family: $zaux-font2;
    src: url('#{$zaux-fonts-path}/#{$zaux-font2-lc}/#{$zaux-font2-lc}-light-italic.woff2') format('woff2'),
    url('#{$zaux-fonts-path}/#{$zaux-font2-lc}/#{$zaux-font2-lc}-light-italic.woff') format('woff');
    font-style: italic;
    font-weight: $zaux-typo-fw-light;
}


@font-face {
    font-family: $zaux-font2;
    src: url('#{$zaux-fonts-path}/#{$zaux-font2-lc}/#{$zaux-font2-lc}-medium.woff2') format('woff2'),
    url('#{$zaux-fonts-path}/#{$zaux-font2-lc}/#{$zaux-font2-lc}-medium.woff') format('woff');
    font-weight: $zaux-typo-fw-medium;
}
*/


/*
@font-face {
    font-family: $zaux-font2;
    src: url('#{$zaux-fonts-path}/#{$zaux-font2-lc}/#{$zaux-font2-lc}-medium-italic.woff2') format('woff2'),
    url('#{$zaux-fonts-path}/#{$zaux-font2-lc}/#{$zaux-font2-lc}-medium-italic.woff') format('woff');
    font-style: italic;
    font-weight: $zaux-typo-fw-medium;
}
*/

@font-face {
    font-family: $zaux-font2;
    src: url('#{$zaux-fonts-path}/#{$zaux-font2-lc}/#{$zaux-font2-lc}-italic.woff2') format('woff2'),
    url('#{$zaux-fonts-path}/#{$zaux-font2-lc}/#{$zaux-font2-lc}-italic.woff') format('woff');
    font-style: italic;
}


@font-face {
    font-family: $zaux-font2;
    src: url('#{$zaux-fonts-path}/#{$zaux-font2-lc}/#{$zaux-font2-lc}-bold.woff2') format('woff2'),
    url('#{$zaux-fonts-path}/#{$zaux-font2-lc}/#{$zaux-font2-lc}-bold.woff') format('woff');
    font-weight: $zaux-typo-fw-bold;
}


@font-face {
    font-family: $zaux-font2;
    src: url('#{$zaux-fonts-path}/#{$zaux-font2-lc}/#{$zaux-font2-lc}-bold-italic.woff2') format('woff2'),
    url('#{$zaux-fonts-path}/#{$zaux-font2-lc}/#{$zaux-font2-lc}-bold-italic.woff') format('woff');
    font-style: italic;
    font-weight: $zaux-typo-fw-bold;
}


@font-face {
    font-family: $zaux-font2;
    src: url('#{$zaux-fonts-path}/#{$zaux-font2-lc}/#{$zaux-font2-lc}-regular.woff2') format('woff2'),
    url('#{$zaux-fonts-path}/#{$zaux-font2-lc}/#{$zaux-font2-lc}-regular.woff') format('woff');
    font-weight: $zaux-typo-fw-regular;
}