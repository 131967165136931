@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("form");

.form {
    scroll-margin-top: $zaux-header-height;

    .form-img-wrapper {

        div {
            height: 100%;
            width: 100%;
            position: relative;
            overflow: hidden;

            .form-img {
                position: absolute;
                height: 100%;
                width: 100%;
            }

        }

    }

    .content-wrapper {

        .h2 {
            margin-bottom: 80px;
        }

        .content {
            padding-top: 40px;
            padding-bottom: 40px;
            border-top: 1px solid $zaux-color-default-black;
            border-bottom: 1px solid $zaux-color-default-black;
            justify-content: space-between;
        }

    }

}



@media (max-width: $breakpoint-medium) {

    .form {
        min-height: 0;
        scroll-margin-top: $zaux-header-height-mobile;

        .content-wrapper {

            .h2 {
                margin-bottom: 40px;
            }

            .content {
                min-height: 0;
                padding-top: 20px;
                padding-bottom: 20px;
            }

        }

    }

}