@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("menu");

.menu {
    padding: 20px 0 19px 0;
    display: none;
    background-color: $zaux-color-default-white;

    a {

        &:hover,
        &:active,
        &:focus {
            text-decoration: underline;
        }

    }

}



@media (max-width: $breakpoint-medium) {

    .menu {

        a {
            margin-bottom: 6px !important;
            display: block;
        }

    }

}