@charset "UTF-8";

@import 'functions'
;

//////////////////////////////////////////////////
// GENERAL SETTING
//////////////////////////////////////////////////

$zaux-system-css-class: 'zaux';
$zaux-font-size-base-px: 16px; // Assumes the browser default, typically 16px
$zaux-app-max-width: 1920px;
$breakpoint-small: 576px;
$breakpoint-medium: 992px;


//////////////////////////////////////////////////
// EASINGS
// @see https://matthewlein.com/tools/ceaser
//////////////////////////////////////////////////

$zaux-ease-outcubic: cubic-bezier(0.215, 0.610, 0.355, 1.000); //easeOutCubic


//////////////////////////////////////////////////
// TRANSITIONS
//////////////////////////////////////////////////

$zaux-transition-0: 0.3s $zaux-ease-outcubic;
$zaux-transition-1: 0.6s $zaux-ease-outcubic;
$zaux-transition-2: 1.2s $zaux-ease-outcubic;
$zaux-transition-3: 2.4s $zaux-ease-outcubic;


//////////////////////////////////////////////////
// COLOR SETS
//////////////////////////////////////////////////

// Custom sets read from JSON (build using Node and Gulp)
// @see src/data/colors.json
@import "../_generated/colors";


//////////////////////////////////////////////////
// Fonts
//////////////////////////////////////////////////

$zaux-font1: 'Albula Pro';
$zaux-font2: 'Maax';

$zaux-font1-lc: 'albulapro';
$zaux-font2-lc: 'maax';

$zaux-typo-fw-thin: 100;
$zaux-typo-fw-extralight: 200;
$zaux-typo-fw-light: 300;
$zaux-typo-fw-regular: 400;
$zaux-typo-fw-medium: 500;
$zaux-typo-fw-semibold: 600;
$zaux-typo-fw-bold: 700;
$zaux-typo-fw-extrabold: 800;
$zaux-typo-fw-black: 900;
$zaux-typo-fw-extrablack: 950;


//////////////////////////////////////////////////
// BOOTSTRAP OVERRIDE
// @see https://github.com/twbs/bootstrap/blob/v5.1.3/scss/_variables.scss
//////////////////////////////////////////////////

$font-family-sans-serif: $zaux-font1;
$font-family-monospace: "Courier New",
monospace !default;

$headings-font-weight: $zaux-typo-fw-bold;

$body-bg: $zaux-color-default-white;
$body-color: $zaux-color-default-black;
$link-color: $zaux-color-default-black;

$grid-breakpoints: ("xs": 0,
  "sm": 576px,
  "md": 768px,
  "lg": 992px,
  "xl": 1280px);

$container-max-widths: (sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1920px);

$spacer: 1rem !default; // 1rem = 16px
$spacers: (0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 3,
  7: $spacer * 6,
  8: $spacer * 9);

$grid-gutter-width: $spacer * 1.5;

$gutters:("0": 0);

@media (max-width: $breakpoint-medium) {

  .row,
  .col {
    --bs-gutter-x: 12px;
  }
}


//////////////////////////////////////////////////
// Z-INDEX
//////////////////////////////////////////////////

$zaux-zindex-header: 22;


//////////////////////////////////////////////////
// COMPONENTS SETTING
//////////////////////////////////////////////////

$component-prefix : 'c-' !default;

// Header
$zaux-header-height: 70px;

$zaux-header-height-mobile: 54px;



//////////////////////////////////////////////////
// MISC
//////////////////////////////////////////////////

$vh-fit-height: calc(100vh - 94px);
$title-letter-spacing: -0.024em;