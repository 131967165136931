@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("header");


.#{$component} {
  height: $zaux-header-height;
}

header {
  position: fixed;
  top: 0;
  width: 100%;
  overflow: hidden;
  z-index: 10000000;

  .container {
    margin: 0 auto;
    width: 100%;

    .navbar-wrapper {
      height: $zaux-header-height;

      .navbar {
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;

        .c-header__logo {

          img {
            height: 40px;
          }

        }

        .hamburger,
        .close {
          position: absolute;
          right: 6px;
          display: block;
          width: 24px;
          height: 24px;
          padding: 0;
        }

        .close {
          transform: scale(0.8);
        }

        a {

          &:hover,
          &:active,
          &:focus {
            text-decoration: underline;
          }

        }

      }

    }

  }

  &:hover {
    overflow: visible;

    .container {

      .menu-wrapper {
        display: block !important;
      }

    }

  }

}

.sticky-header {
  -webkit-position: sticky !important;
  position: sticky !important;
  top: 0;
}



@media (max-width: $breakpoint-medium) {

  .#{$component} {
    height: $zaux-header-height-mobile;
  }

  .navbar-wrapper {
    height: $zaux-header-height-mobile !important;

    .navbar {

      .c-header__logo {

        img {
          height: 30px !important;
        }

      }

    }

  }

}