@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("article");

.article {

    .article-top {

        .article-img {
            height: 50vh;
            max-height: 900px;
        }

    }

    .article-title {
        margin: 40px 0;

        .h2 {
            padding: 0 0 0 6px;
        }

    }

    .article-info {

        .line-wrapper {

            .line {
                border-top: 1px solid $zaux-color-default-black;
                margin-top: 20px;
            }

        }

    }

    .article-end {
        margin-top: 40px;
    }

}



@media (max-width: $breakpoint-medium) {

    .article {

        .article-top {

            .article-img {
                max-height: 300px;
                margin-bottom: 20px;
            }

        }

        .article-title {
            margin: 40px 0 12px 0;

            .h2 {
                padding: 0 !important;
                font-size: $zaux-main-title-small-size;
                line-height: $zaux-main-title-small-size;
            }

        }

        .article-info {

            .tags-wrapper {
                margin-bottom: 40px;
            }

        }

        .article-end {

            .next {
                margin-top: 40px;
            }

        }

    }

}