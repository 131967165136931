@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("showroom-description");


.showroom-description {
    margin-top: -40px;

    .showroom-description-content {

        .h3 {
            margin-bottom: 40px;
            line-height: 68px;
            max-width: 1920px;
        }

        .paragraph {
            margin-bottom: 80px;
        }

    }

    span {
        letter-spacing: -0.160em;
    }

}



@media (max-width: $breakpoint-medium) {

    .showroom-description {
        margin-top: 0;

        .showroom-description-content {

            .h3 {
                margin-bottom: 20px;
                line-height: $zaux-main-title-small-size;
            }

            .paragraph {
                margin-bottom: 40px;
            }

        }

        .opening {
            font-size: 20px;

            span {
                letter-spacing: -0.160em;
            }

        }

        a {
            margin-top: 40px;
            font-size: 20px;
        }

    }

}