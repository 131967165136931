@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("about-content");

.about-page {

    .about-section {
        margin-bottom: 320px;

        .inner {

            .top-wrapper {
                height: calc(100vh - 70px);
                max-height: 1080px;
                display: flex;
                flex-direction: column;

                .top-img-wrapper {
                    height: 100px;
                    flex-grow: 1;

                    img {
                        height: 100%;
                        width: 100%;
                    }

                }

                .h2 {
                    margin: 40px 0 40px 0;
                }

            }

            .sticky {
                position: sticky;
                top: $zaux-header-height;
            }

            .h3 {
                margin-bottom: 20px;
            }

            .text {
                margin-bottom: 80px;
            }

        }

        .fake {
            margin-bottom: -56px;

            .top-wrapper {
                margin-bottom: 296px;
            }

        }

        .people {
            margin-bottom: 24px;
        }

        .logos {
            margin-bottom: -136px;

            .client-logo {
                height: 40px;
                margin-bottom: 160px;

                img {
                    width: 50%;
                    max-width: 200px;
                    height: auto;
                }

            }

        }

    }

    .h1 {
        margin: 40px 0 40px 0;
    }

}



@media (max-width: $breakpoint-medium) {

    .about-page {

        .about-section {
            margin-bottom: 160px;

            .inner {

                .top-wrapper {
                    height: calc(100vh - 54px);
                    max-height: none;

                    .h2 {
                        margin: 20px 0 20px 0;
                        font-size: 45px;
                        line-height: 45px;
                    }

                }

                .sticky {
                    position: static;
                }

                .h3 {
                    margin-bottom: 20px;
                }

                .text {
                    margin-bottom: 40px;
                }

            }

            .content {
                margin-top: 80px;
            }

            .people {
                margin-bottom: 0;
            }

            .logos {
                margin-bottom: -80px;

                .client-logo {
                    height: 30px;
                    margin-bottom: 80px;

                    img {
                        width: 50%;
                        max-width: 100px;
                        height: auto;
                    }

                }

            }

        }

    }

}