@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("showroom-top");

.showroom-top {
    height: calc(100vh - 70px);
    max-height: 1080px;
    display: flex;
    flex-direction: column;

    .showroom-img-wrapper {
        height: 100px;
        flex-grow: 1;

        .showroom-img {
            height: 100%;
        }

    }

    .h1 {
        margin: 40px 0 40px 0;
    }

}



@media (max-width: $breakpoint-medium) {

    .showroom-top {
        height: calc(100vh - 54px);
        max-height: none;

        .showroom-img-wrapper {

            img {
                height: 50px !important;
                flex-grow: 1;
                margin-top: 12px;

                &:first-child {
                    margin-top: 0;
                }

            }

        }

        .h1 {
            margin: 20px 0 20px 0;
        }

    }


}