@charset "UTF-8";

@import '../../assets/scss/components/base';

$component : componentClass('wrapper');

.#{$component} {

  &--w1 {
    max-width: $zaux-app-max-width;
  }

}

[class*="#{$component}--"] {
  margin-right: auto;
  margin-left: auto;
}