@charset "UTF-8";

$zaux-helper-name: "fit";

.zaux-#{$zaux-helper-name} {
  &-cover {
    &-center-center {
      @include objectfit(cover, center center);
    }
  }
}
